/*
This is where you write custom SASS
*/
body {
    color: #515151;
}
.btn {
    min-width: 130px;
    &.btn-primary,
    &.btn-secondary {
        &:hover,
        &:active,
        &:focus {
            color: #fff !important;
        }
    }
}

header {
    .header-tagline {
        letter-spacing: -0.025em;
        line-height: 33px;
        font-family: 'Montserrat', sans-serif;
    }
}

nav {
    @media screen and (min-width: 992px) {
        #main-menu {
            ul {
                .current {
                    color: #fff !important;
                    background: $primary;
                }
                .sub-arrow {  
                    -webkit-transform: rotate(270deg);
                    -ms-transform: rotate(270deg);
                    transform: rotate(270deg);
                }
                .highlighted {
                    color: #fff !important;
                    background: $secondary;
                }
            }
            > li {
                border-top: 2px solid transparent;
                &.active, &.show, &:hover {
                        border-top: 2px solid $primary;
                }
            }
        }
        &.navbar ul.navbar-nav>li {
            margin: 0 12px;
        }
    }
}

section.carousel-section {
    @media screen and (max-width: 479px) {
        .banner-tagline {
            font-size: 28px !important;
        }
        [style*="height: 530px"] {
            height: 350px !important;
        }
    }
    @media screen and (max-width: 767px) {
        .banner-tagline {
            font-size: 41px;
        }
    }
    @media screen and (max-width: 991px) {
        .owl-stage-outer .container .row.align-items-center {
            height: 45vw!important;
            min-height: 350px;
        }
    }
}

section.address-cta {
    .addr-company-name {
        font-size: 26px;
    }
    @media screen and (max-width: 767px) {
        span.arrow-wrapper {
            -webkit-transform: rotate(90deg);
            -ms-transform: rotate(90deg);
            transform: rotate(90deg);
        }
    }
    @media screen and (max-width: 480px) {
        .addr-company-name {
            font-size: 22px;
        }
        .addr-company-info {
            font-size: 17px;
        }
    }
}

section.cta-section {
    .cta-link {
        opacity: .75;
    }
}

section.testimonials-section {
    @media screen and (max-width: 991px) {
        .testimonials-image {
            height: 45vw;
        }
    }
    .testimonials {
        .testimonials-col {
            .owl-stage {
                cite {
                    span:first-of-type.d-block:before {
                        content: "- ";
                    }
                }
            }
        }
    }
}

section.contact-section {
    #btnSend {
        width: 100%;
        max-width: 160px;
    }
}

section.services-section {
    @media screen and (max-width: 991px) {
        .services-image {
            height: 45vw;
        }
    }
}


.bounce {
    animation: bounceRight 2s infinite;
}

@media screen and (max-width: 767px) {
    .bounce {
        animation: bounceDown 2s infinite;
    }
}

@keyframes bounceRight {
    0%, 50%, 80%, 100% {
        transform: translateX(0);
    }
    20% {
        transform: translateX(25px);
    }
    60% {
        transform: translateX(15px);
    }
}

@keyframes bounceDown {
    0%, 50%, 80%, 100% {
        transform-origin: center;
        transform: translateY(0) rotate(90deg);
    }
    20% {
        transform-origin: center;
        transform: translateY(15px) rotate(90deg);
    }
    60% {
        transform-origin: center;
        transform: translateY(7px) rotate(90deg);
    }
}

body.interior {
    .card {
        padding: 30px 15px;
        border: 1px solid rgba(33,37,41,0.15);
        .card-body {
            p.h3 {
                text-transform: uppercase;
                color: #3b3b3b;
            }
        }
        label {
            display: none;
        }
        .form-group {
            margin-bottom: 0px;
            margin-top: 0px !important;
            padding-top: 10px;
        }
        .btn {
            margin-top: 20px;
        }
    }
}

body.interior.page-type-11 {
    label {
        font-size: .8rem;
        line-height: 1.2em;
        font-weight: 700;
        text-transform: initial;
    }
}

@media (max-width: 1199px) {}
@media (max-width: 991px) {
    .carousel-section .display-3 {
        font-size: 40px !important
    }
    .owl-carousel .owl-stage .panel2 {
        background-position: 51% 50% !important;
    }
}
@media (max-width: 767px) {
    #ctl00_interiorBanner {
        min-height: auto !important;
        height: 30vw !important;
    }
    .owl-carousel .owl-stage .panel4 {
        background-position: 67% 50% !important;
    }
    .owl-carousel .owl-stage .panel2 {
        background-position: 65% 50% !important;
    }
    .carousel-section .display-3 {
        display: none;
    }
}
@media (max-width: 575px) {
    .owl-carousel .owl-stage .panel4 {
        background-position: 64% 50% !important;
    }
    .owl-carousel .owl-stage .panel2 {
        background-position: 63% 50% !important;
    }
}
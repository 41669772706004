.navbar {

    ul.navbar-nav {
        a.current,
        a.highlighted {
            color: $nav-current-color !important;
        }
        > li {
            position: relative;
            > a {
                font-size: $nav-font-size;
                font-weight: $nav-font-weight;
                font-style: $nav-font-style;
                line-height: $nav-line-height;
                letter-spacing: $nav-letter-spacing;
                color: $nav-color;
                text-transform: $nav-text-transform;
                margin: $nav-margin !important;
                padding: $nav-padding !important;
                display: flex;
                align-items: center;
                justify-content: space-between;
                > .sub-arrow {
                    display: inline-block;
                }
                &:hover,
                &.bg-light {
                    background: $nav-hover-background !important;
                    color: $nav-hover-color !important;
                }
            }
            .dropdown-menu {
                background-color: $nav-dropdown-background;
                border-radius: $nav-dropdown-border-radius !important;
                border: $nav-dropdown-border;
                box-shadow: $nav-dropdown-box-shadow;
                padding: $nav-dropdown-container-padding !important;
                min-width: 15rem !important;
                max-width: 20rem !important;
                li {
                    a {
                        font-family: $nav-dropdown-font-family;
                        font-size: $nav-dropdown-font-size;
                        line-height: $nav-dropdown-line-height;
                        padding: $nav-dropdown-item-padding;
                        letter-spacing: $nav-dropdown-letter-spacing;
                        font-weight: $nav-dropdown-font-weight;
                        text-transform: $nav-dropdown-text-transform;
                        font-style: $nav-dropdown-font-style;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        color: $nav-dropdown-color;
                        &:hover,
                        &.bg-light {
                            background: $nav-dropdown-hover-background !important;
                            color: $nav-dropdown-hover-color !important;
                        }
                    }
                }
            }
        }
        &[class*="collapsible"] {
            > li {
                .dropdown-menu {
                    background: transparent !important;
                    width: 100% !important;
                    min-width: 1px !important;
                    max-width: 9999px !important;
                    box-shadow: none !important;
                    padding: 0 0 0 10px !important;
                    margin: 0 !important;
                    > li:last-child {
                        padding-bottom: 10px;
                    }
                    a {
                        font-size: $nav-font-size;
                        font-weight: $nav-font-weight;
                        font-style: $nav-font-style;
                        line-height: $nav-line-height;
                        letter-spacing: $nav-letter-spacing;
                        color: $nav-color;
                        text-transform: $nav-text-transform;
                        margin: $nav-margin;
                        padding: $nav-padding;
                        &:hover,
                        &.bg-light {
                            background: $nav-hover-background !important;
                            color: $nav-hover-color !important;
                        }
                    }
                }
            }
        }
    }
    &.navbar-expand-xl {
        @include media-breakpoint-down(lg) {
            .navbar-collapse {
                position: fixed;
                z-index: 9999;
                top: 0;
                right: 0;
                bottom: 0;
                left: auto;
                height: 100% !important;
                width: 80%;
                max-width: 400px;
                transition: 0.4s ease;
                opacity: 0;
                visibility: hidden;
                transform: translate3d(50px,0,0);
                display: block !important;
                &.show {
                    opacity: 1;
                    visibility: visible;
                    transform: translate3d(0,0,0);
                }
                .container {
                    overflow-y: auto;
                }
                ul.navbar-nav {
                    a {
                        font-size:  $mobile-nav-font-size !important;
                        line-height:  $mobile-nav-line-height !important;
                        padding:  $mobile-nav-padding !important;
                    }
                }
            }
        }
    }
    &.navbar-expand-lg {
        @include media-breakpoint-down(md) {
            .navbar-collapse {
                position: fixed;
                z-index: 9999;
                top: 0;
                right: 0;
                bottom: 0;
                left: auto;
                height: 100% !important;
                width: 80%;
                max-width: 400px;
                transition: 0.4s ease;
                opacity: 0;
                visibility: hidden;
                transform: translate3d(50px,0,0);
                display: block !important;
                &.show {
                    opacity: 1;
                    visibility: visible;
                    transform: translate3d(0,0,0);
                }
                .container {
                    overflow-y: auto;
                }
                ul.navbar-nav {
                    a {
                        font-size:  $mobile-nav-font-size !important;
                        line-height:  $mobile-nav-line-height !important;
                        padding:  $mobile-nav-padding !important;
                    }
                }
            }
        }
    }
    &.navbar-expand-md {
        @include media-breakpoint-down(sm) {
            .navbar-collapse {
                position: fixed;
                z-index: 9999;
                top: 0;
                right: 0;
                bottom: 0;
                left: auto;
                height: 100% !important;
                width: 80%;
                max-width: 400px;
                transition: 0.4s ease;
                opacity: 0;
                visibility: hidden;
                transform: translate3d(50px,0,0);
                display: block !important;
                &.show {
                    opacity: 1;
                    visibility: visible;
                    transform: translate3d(0,0,0);
                }
                .container {
                    overflow-y: auto;
                }
                ul.navbar-nav {
                    a {
                        font-size:  $mobile-nav-font-size !important;
                        line-height:  $mobile-nav-line-height !important;
                        padding:  $mobile-nav-padding !important;
                    }
                }
            }
        }
    }
    &.navbar-expand-sm {
        @include media-breakpoint-down(xs) {
            .navbar-collapse {
                position: fixed;
                z-index: 9999;
                top: 0;
                right: 0;
                bottom: 0;
                left: auto;
                height: 100% !important;
                width: 80%;
                max-width: 400px;
                transition: 0.4s ease;
                opacity: 0;
                visibility: hidden;
                transform: translate3d(50px,0,0);
                display: block !important;
                &.show {
                    opacity: 1;
                    visibility: visible;
                    transform: translate3d(0,0,0);
                }
                .container {
                    overflow-y: auto;
                }
                ul.navbar-nav {
                    a {
                        font-size:  $mobile-nav-font-size !important;
                        line-height:  $mobile-nav-line-height !important;
                        padding:  $mobile-nav-padding !important;
                    }
                }
            }
        }
    }
}


.dropdown {
    .dropdown-menu {
        background-color: $nav-dropdown-background;
        border-radius: $nav-dropdown-border-radius !important;
        border: $nav-dropdown-border;
        box-shadow: $nav-dropdown-box-shadow;
        padding: $nav-dropdown-container-padding !important;
        min-width: 15rem !important;
        max-width: 25rem !important;
        transition: 0.4s ease;
        opacity: 0;
        display: block;
        visibility: hidden;
        margin: 1rem 0 0 (-$nav-link-padding-x);
        &:before {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            height: 1rem;
            width: 1rem;
            transform: rotate(45deg) translate3d(0,-50%,0);
            background-color: $nav-dropdown-background;
            border-radius: $nav-dropdown-border-radius/2 !important;
        }
        &.show {
            opacity: 1;
            visibility: visible;
        }
        a {
            font-family: $nav-dropdown-font-family;
            font-size: $nav-dropdown-font-size;
            line-height: $nav-dropdown-line-height;
            letter-spacing: $nav-dropdown-letter-spacing;
            font-weight: $nav-dropdown-font-weight;
            text-transform: $nav-dropdown-text-transform;
            font-style: $nav-dropdown-font-style;
            color: $nav-dropdown-color;
            padding: $nav-link-padding-y $nav-link-padding-x;
            display: block;
            &:hover {
                background: $nav-dropdown-hover-background !important;
                color: $nav-dropdown-hover-color !important;
            }
        }
    }
}